import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

interface HttpResult {
	status?: number,
	data?: any,
	errorContent?: any
}

const ApiService = {
	_requestInterceptor: 0,
	_401interceptor: 0,

	init(baseURL: string | undefined) {
		axios.defaults.baseURL = baseURL;
	},

	removeHeader() {
		axios.defaults.headers.common = {};
	},

	get(path: string) {
		return axios.
			get(path).
			then((response: AxiosResponse) => ({ status: 200, data: response?.data }) as HttpResult).
			catch((error: any) => this.handleError(error));
	},


	handleError(error: any) {
		let errorResult: HttpResult = {
			status: undefined,
			errorContent: undefined
		};
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			errorResult = {
				status: error.response.status,
				errorContent: {
					title: error.response.data.title,
					message: error.response.data.detail,
				}
			};
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest
			console.log(error.request);
			errorResult = {
				status: 990,
				errorContent: error.request
			};
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
			errorResult = {
				status: 991,
				errorContent: {
					title: 'Something went wrong',
					message: error.message,
				}
			};
		}
		console.error(error.config);
		return errorResult;
	},

	customRequest(data: AxiosRequestConfig) {
		return axios(data);
	},

}

export default ApiService;