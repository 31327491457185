import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/home' },
  { path: '/home', name: 'Home', component: () => import('@/views/HomePage.vue') },
  { path: '/label/:orgAlias/:labelAlias', name: 'Label', component: () => import('@/views/LabelPage.vue') },
  { path: '/lot/:orgAlias/:labelAlias/:lotNumber', name: 'Lot', component: () => import('@/views/LotPage.vue') },
  { path: '/lots/slide/:orgAlias/:labelAlias/:lotNumber', name: 'Lots', component: () => import('@/views/LotSlidePage.vue') },
  { path: '/acteur', name: 'Acteur', component: () => import('@/views/ActeurPage.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
